window.onload = () => {

	document.oncopy = function () {
	var bodyElement = document.body;
	var selection = getSelection();
	var href = 'hydrofuel.ru';
	var copyright = `<br><br>Источник: ${href}<br>© ООО "Газохим Инжиниринг"`;
	var text = selection + copyright;
	var divElement = document.createElement('div');
	divElement.style.position = 'absolute';
	divElement.style.left = '-99999px';
	divElement.innerHTML = text;
	bodyElement.appendChild(divElement);
	selection.selectAllChildren(divElement);
	setTimeout(function () {
		bodyElement.removeChild(divElement);
	}, 0);
};

// document.ondragstart = () => false;
// document.onselectstart = () => false;
// document.oncontextmenu = () => false;
	let setHeader = function () {
	if ($('.header').offset().top > 65) {
		$('.header').addClass('header--white');
	} else {
		$('.header').removeClass('header--white');
	}
};

let sections = $('section'),
	headerHeight,
	menuItem;

$(window).innerWidth() < 1080 ? headerHeight = 60 : headerHeight = 65;

setHeader();

$(window).on('scroll', function (e) {
	e.preventDefault();
	setHeader();
});

$('.header__link[href="#"]').on('click', function (e) {
	e.preventDefault();
	let target = $(this).data('target'),
		offset = $(`.${target}`).offset().top - headerHeight;
	$('html, body').animate({
		scrollTop: offset
	}, 1000);
});

document.addEventListener('scroll', () => {
	for (let section of sections) {
		if (section.offsetTop - headerHeight <= window.scrollY) {
			menuItem = $(document.querySelector(`[data-target="${section.classList}"]`));
			if (!menuItem.hasClass('header__link--active')) {
				setTimeout(() => {
					$('.header__link--active').removeClass('header__link--active');
					menuItem.addClass('header__link--active');
				}, 1000);
			}
		// } else {
			// $('.header__link--active').removeClass('header__link--active');
		}
	}
});

$('.header__hamburger').on('click', function () {
	$('.header').toggleClass('header--opened');
	$(this).toggleClass('header__hamburger--active');
	$('.header__list').toggleClass('header__list--opened');
	// $('.header').toggleClass('header--opened');
	// if ($('.header').hasClass('header--opened')) {
		// 	$('.header').find('.header__nav').css('display', 'flex').hide(0).slideDown(300);
		// } else {
			// 	$('.header').find('.header__nav').slideUp(300);
	// }
	// $('.header__link[href="#"]').on('click', () => {
	// 	$('.header').removeClass('header--opened').find('.header__nav').slideUp(300);
	// });
});

$('.header__list-links-item, .header__list-item').on('click', function (e) {
	if (!$(e.target).hasClass('header__list-arrow')) {
		$('.header').toggleClass('header--opened');
		$('.header__hamburger').toggleClass('header__hamburger--active');
		$('.header__list').toggleClass('header__list--opened');
	}
});

$('.header__list-arrow').on('click', function (e) {
	e.preventDefault();
	$(this).toggleClass('header__list-arrow--active');
	$(this).parent().siblings('.header__list-links').slideToggle(300);
});
	let video = $('.hero__video'),
	hero = $('.hero'),
	heroWidth = hero.innerWidth(),
	heroHeight = hero.innerHeight();

let setVideo = function () {
	if (heroWidth/16 > heroHeight/9) {
		video.css('width', heroWidth + 'px');
		video.css('height', 'auto');
	} else if (heroWidth/16 < heroHeight/9) {
		video.css('width', 'auto');
		video.css('height', heroHeight + 'px');
	}
}

document.body.onresize = () => {
	heroWidth = hero.innerWidth();
	heroHeight = hero.innerHeight();
	setVideo();
	if ($(window).innerWidth() < 1021) {
		$('.hero__video').remove();
	}
};

setVideo();
	let norm = {
	methane: 0.3,
	water: 0.000603,
	electricity: 2,
	air: 1
};

let setNum = function (num) {
	return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

$('.calculator__btn').on('click', function (e) {
	e.preventDefault();
	let isEmpty = true,
		{methane, water, electricity, air} = norm,
		result;

	$('.calculator__label-input').each(function () {
		if ($(this).val() !== '') {
			isEmpty = false;
		}
	});

	if (isEmpty) {

	} else {
		methane = methane * $('.calculator__label-input[name="methane"]').val();
		water = water * $('.calculator__label-input[name="water"]').val();
		electricity = electricity * $('.calculator__label-input[name="electricity"]').val();
		air = air * $('.calculator__label-input[name="air"]').val();
		result = setNum(parseInt(methane + water + electricity + air));
		$('.calculator__sum-result-value').text(result);
	}
});

$('.about__list-item--calc').on('click', function () {
	var target = $(this).attr('data-target');
	$('html, body').animate({
		scrollTop: $(target).offset().top - 65
	}, 1000);
	return false;
});
	$('.popup__close').on('click', function () {
	$(this).closest('.popup').fadeOut(300);
});

$('.popup').on('click', function (e) {
	if (!$(e.target).closest('.popup__window').length) {
		$(this).fadeOut(300);
	}
});

$('.popup__window').on('click', function (event) {
	event.stopPropagation();
});
	$('.usage__item').on('click', function (e) {
	e.preventDefault();
	let data = $(this).attr('href').substr(1);
	$(`.popup[data-popup=${data}]`).css('display', 'flex').hide().fadeIn(300);
});
	$('.scheme__pulse').on('click', function () {
	$(this).parent().find('.scheme__clear').fadeIn(300);
});

$('.scheme__tag').on('click', function () {
	let $parent = $(this).closest('.scheme');
	if (!$(this).hasClass('scheme__tag--active')) {
		let tag = $(this).data('tag');
		$parent.find('.scheme__tag--active').removeClass('scheme__tag--active');
		$(this).addClass('scheme__tag--active');
		$parent.find('.scheme__desc').fadeIn(300);
		$parent.find('.scheme__desc-text').hide(0);
		$parent.find('.scheme__line').hide(0);
		$parent.find(`.scheme__line[data-tag=${tag}]`).fadeIn(300);
		$parent.find(`.scheme__desc-text[data-tag=${tag}]`).fadeIn(300);
	}
})

$(document).on('click', function (event) {
	if (!$(event.target).closest('.scheme__tag--active').length) {
		$('.scheme__tag--active').removeClass('scheme__tag--active');
		$('.scheme__desc').fadeOut(300);
		$('.scheme__line').fadeOut(300);
	}
});

$('.scheme__tag--active').on('click', function (event) {
	event.stopPropagation();
});

const defaultWidth = 2029;

let schemeScale = function () {
	let windowWidth = $(window).innerWidth(),
		coeff = windowWidth / defaultWidth;
	$('.scheme').each(function () {
		$(this).css('transform', `scale(${coeff})`);
		$(this).parent().css('height', `${$(this).innerHeight() * coeff}`)
	});
	$('.fuel__scroll').hide(0);
};

if ($(window).innerWidth() < 1080) {
	schemeScale();
} else {
	if ($('.scheme').length) {
		let fuelScheme = new PerfectScrollbar('.fuel .scheme-fuel .wrapper');
		let electricityScheme = new PerfectScrollbar('.electricity .scheme-electricity .wrapper');
	}
}

document.body.onresize = () => {
	if ($(window).innerWidth() < 1080) {
		schemeScale();
	} else {
		if ($('.scheme').length) {
			let fuelScheme = new PerfectScrollbar('.fuel .scheme-fuel .wrapper');
			let electricityScheme = new PerfectScrollbar('.electricity .scheme-electricity .wrapper');
		}
	}
};

$('.container.scheme-fuel .wrapper').on('scroll', () => {
	$('.fuel__scroll').fadeOut(300);
});
$('.container.scheme-electricity .wrapper').on('scroll', () => {
	$('.electricity__scroll').fadeOut(300);
});

	$(function () {
	if ($('.video').length && $(window).innerWidth() < 1021) {
		let inter = setInterval(() => {
			let video = document.querySelector('.hero__video');
			if (video.readyState === 4) {
				$('.preloader').fadeOut(300);
				clearInterval(inter);
			}
		}, 1000);
	} else {
		setTimeout(() => {
			$('.preloader').fadeOut(300);
		}, 1000);
	}
});

}
